/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Button';
import useTypedSelector from 'hooks/useTypedSelector';
import { Divider, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
// import { IconButton } from 'material-ui';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getAllCompleteRequestAction } from 're-ducks/locker';
import { fetchUserProfile, fetchUserSignature } from 're-ducks/user';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import instance from 'services/axios';
import IconButton from '@mui/material/IconButton';
import SignaturePolicy from 'container/document/SignaturePolicy';
import styles from './Upload.module.scss';

export interface Props {
  label: string;
  fileRule: string;
  iconName?: string;
  maxFilesize: number;
  showPreview?: boolean;
  placeholder: string;
  isSaving: boolean;
  onSave: (data: object) => void;
  fileURL: any;
  fetching: boolean;
  showAgreement: boolean;
  prevStep: () => void;
}

const UploadSignature = ({ label, placeholder, fileRule, iconName, showAgreement, maxFilesize, showPreview = false, isSaving, onSave, fetching, fileURL, prevStep }: Props) => {
  const [filename, setFilename] = useState<any>();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { locker }: any = useTypedSelector((state) => state);
  const user: any = useTypedSelector((state) => state);
  const [fileBase64, setFileBase64] = useState<any>("");
  const [imageFiles, setImageFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [previewFiles, setPreviewFiles] = useState<any>("");
  const [removed, setRemoved] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [fileData, setFileData] = useState<any>('');
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [fetchingFiles, setFetchingFiles] = useState<boolean>(true);
  const [signatureFile, setSignatureFile] = useState<string>('');
  const [isDragging, setIsDragging] = useState(false);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement> | { target: { files: FileList } }): void => {
    const { files }: any = e.target as HTMLInputElement;

    for (let i = 0; i < files.length; i += 1) {
      if (files[i].size > 2097152) {
        setErrorMessage("File is too big!");

        return;
      }
      const reader = new FileReader();
      const params = files[i];
      reader.onloadend = () => {
        setFileData(reader.result);
        setErrorMessage("");
      };
      setPreviewFiles(URL.createObjectURL(files[0]));
      reader.readAsDataURL(params);
    }
    (e.target as HTMLInputElement).value = '';
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile({ target: { files: e.dataTransfer.files } });
      e.dataTransfer.clearData();
    }
  };

  const handleClick = (e) => {
    const uploadInput = document.getElementById('upload-comp');
    if (uploadInput) {
      uploadInput.click();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClick(e);
    }
  };

  const fetchAllCompleteRequest = useCallback(
    (nextPage: any = 1, itemsPerPage: any = 10) => {
      setLoading(true);
      const params = {
        page: nextPage === 0 ? 1 : nextPage,
        per_page: itemsPerPage
      };
      dispatch(
        getAllCompleteRequestAction(
          { params },
          (res: any) => {
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    fetchAllCompleteRequest();
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUserProfile(
        {},
        () => { },
        () => { }
      )
    );
  }, [dispatch]);

  const saveImage = async () => {
    if (fileData) {
      onSave({
        file: fileData,
        type: 'Signature',
        category: 'Upload',
        done: () => {
          setIsDisabled(true);
          setFileData('');
          dispatch(
            fetchUserSignature(
              () => {
                setFetchingFiles(false);
              },
              (error) => {
                setFetchingFiles(false);

                return error;
              }
            )
          );
          toast.success('Signature created successfully.');
        },
        nextTab: {
          label: 'Upload'
        }
      });
    }
  };

  useEffect(() => {
    if (fileURL) {
      setSignatureFile(fileURL?.Signature?.find(
        (signature) => signature?.category === "Upload"
      )?.file || '');
    }
  }, [fileURL]);

  const uploadDocument = () => {
    setLoading(true);
    if (fileData.title) {
      instance.post('/notary/notary-locker', fileData)
        .then((res: any) => {
          fetchAllCompleteRequest();
          toast.success(res?.data?.data?.message);
          setFileData({ title: '', files: [] });
          setPreviewFiles([]);
          setImages([]);
          setFilename('');
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (!showAgreement && isSaving) {
      setIsDisabled(true);
    } else if (showAgreement && (isSaving || !acceptPolicy)) {
      setIsDisabled(true);
    } else if (showAgreement && (!fileData || !acceptPolicy)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isSaving, acceptPolicy, showAgreement]);

  // eslint-disable-next-line no-console
  // console.log(fileData, 'files', fileURL, signatureFile)

  return (
    <div className="mt-2">
      <div className='flex flex__start flex__item-center'>
        <div
          className={`${styles.upload__wrapper} ${isDragging ? styles.dragging : ''}`}
          style={{ flexDirection: 'column' }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          role="button"
          tabIndex={0}
        >
          {!showPreview && (
            <>
              <p className={styles.upload__label}>{label}</p>
              <div className={styles.upload} style={{ flexDirection: 'column' }}>
                {iconName ? (
                  <img className="mr-1" src={iconName} alt="icon" />
                ) : (
                  <div style={{ backgroundColor: 'lightgray', height: '45px', width: '45px', textAlign: 'center', padding: '0.5rem', borderRadius: '50%' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-v-364156fa="">
                      <path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" data-v-364156fa="" />
                    </svg>
                  </div>
                )}
                <div>
                  <span className={styles.upload__title}>
                    {filename || (
                      <span>
                        {placeholder}
                        <div style={{ color: '#828282', display: 'flex', flexDirection: 'row' }}>
                          <span style={{ color: '#828282' }}>{fileRule}</span>
                        </div>
                      </span>
                    )}
                  </span>
                  <span className={styles.upload__meta}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                      <Button className="my-1" theme="primary" width={120} onClick={() => handleFile}>
                        Browse
                      </Button>
                      <span>PNG, JPEG OR JPG</span>
                      <span>Maximum size {maxFilesize} Mb per file</span>
                    </div>
                  </span>
                </div>
              </div>
            </>
          )}

          <input id="upload-comp" type="file" accept="image/*" className="upload__button" style={{ display: 'none' }} onChange={handleFile} />
        </div>

        <div>
          <img className="preview " src={previewFiles === '' ? signatureFile : previewFiles} alt="" />
        </div>
      </div>

      <div>
        {showAgreement && <SignaturePolicy policy='By Uploading this signature, I agree that it is as valid as my hand-written signature to the extent allowed by law' acceptPolicy={acceptPolicy} setAcceptPolicy={setAcceptPolicy} />}
        <div className="bb-1 mb-2" />
        <Button onClick={prevStep} type="button" theme="grey" variant="outline" style={{ borderRadius: '50%' }} className='mr-2'>
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9998 7.26542C16.9998 7.53064 16.8945 7.785 16.7069 7.97253C16.5194 8.16007 16.2651 8.26542 15.9998 8.26542H4.41383L8.70783 12.5574C8.80081 12.6504 8.87456 12.7608 8.92488 12.8823C8.9752 13.0037 9.0011 13.1339 9.0011 13.2654C9.0011 13.3969 8.9752 13.5271 8.92488 13.6486C8.87456 13.7701 8.80081 13.8804 8.70783 13.9734C8.61486 14.0664 8.50448 14.1402 8.383 14.1905C8.26152 14.2408 8.13132 14.2667 7.99983 14.2667C7.86835 14.2667 7.73815 14.2408 7.61667 14.1905C7.49519 14.1402 7.38481 14.0664 7.29183 13.9734L1.29183 7.97342C1.19871 7.88053 1.12482 7.77018 1.07441 7.64869C1.024 7.5272 0.998047 7.39696 0.998047 7.26542C0.998047 7.13389 1.024 7.00365 1.07441 6.88216C1.12482 6.76067 1.19871 6.65031 1.29183 6.55742L7.29183 0.557424C7.47961 0.36965 7.73428 0.26416 7.99983 0.26416C8.26539 0.26416 8.52006 0.36965 8.70783 0.557424C8.89561 0.745197 9.0011 0.999872 9.0011 1.26542C9.0011 1.53098 8.89561 1.78565 8.70783 1.97342L4.41383 6.26542H15.9998C16.2651 6.26542 16.5194 6.37078 16.7069 6.55832C16.8945 6.74585 16.9998 7.00021 16.9998 7.26542Z" fill="#A1A0A0" />
          </svg>
        </Button>
        <Button disabled={isDisabled} className="mb-1" theme="primary" width={161} loading={isSaving} onClick={() => saveImage()}>
          Save
        </Button>
      </div>
    </div>
  );
};

UploadSignature.defaultProps = {
  iconName: '',
  showPreview: false
};

export default UploadSignature;
