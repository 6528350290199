export const DO_SIGNIN = 'DO_SIGNIN';
export const DO_SIGNUP = 'DO_SIGNUP';
export const DO_SIGNOUT = 'DO_SIGNOUT';
export const DO_RESET_PASSWORD = 'DO_RESET_PASSWORD';
export const DO_SET_PASSWORD =' DO_SET_PASSWORD';
export const DO_RE_SEND_EMAIL = 'DO_RE_SEND_EMAIL';
export const DO_FORGET_PASSWORD = 'DO_FORGET_PASSWORD';
export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';
export const DO_VERIFY_NOTIFY_ID = 'DO_VERIFY_NOTIFY_ID';
export const DO_COMPLETE_PROFILE = 'DO_COMPLETE_PROFILE';
export const FORGOT_PASS_RESET_STATE = 'FORGOT_PASS_RESET_STATE';
export const SET_SIGNIN_DATA = 'SET_SIGNIN_DATA';
export const DO_CHANGE_PASSWORD = 'DO_CHANGE_PASSWORD';
export const DO_REFRESH_TOKEN = 'DO_REFRESH_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';

