export const SET_USER_SIG = 'SET_USER_SIG';
export const SAVE_USER_SIG = 'SAVE_USER_SIG';
export const SAVE_USER_SIG_STATE = 'SAVE_USER_SIG_STATE';
export const EDIT_USER_SIG = 'EDIT_USER_SIG';
export const SET_USER_SIG_FILE = 'SET_USER_SIG_FILE';
export const FETCH_USER_SIGNATURE = 'FETCH_USER_SIGNATURE';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const UPDATE_USER_ID_STATE = 'UPDATE_USER_ID_STATE';
export const FETCH_STAMP_AND_SEAL = "FETCH_STAMP_AND_SEAL";
export const EDIT_NOTARY_FILES = 'EDIT_NOTARY_FILES';
export const UPLOAD_NOTARY_FILES = 'UPLOAD_NOTARY_FILES';
export const UPLOAD_BANK_DETAILS = 'UPLOAD_BANK_DETAILS';
export const SAVE_BANK_DETAILS = 'SAVE_BANK_DETAILS';
export const FETCH_BANKS = 'FETCH_BANKS';
export const FETCH_BANKS_DETAILS = 'FETCH_BANKS_DETAILS';
export const FETCH_DASHBOARD_DETAILS = 'FETCH_DASHBOARD_DETAILS';
export const SAVE_DASHBOARD_DETAILS = 'SAVE_DASHBOARD_DETAILS';
export const CREATE_NOTARY_CALENDAR = 'CREATE_NOTARY_CALENDAR';
export const GET_NOTARY_CALENDAR = 'GET_NOTARY_CALENDAR';
export const SAVE_NOTARY_CALENDAR = 'SAVE_NOTARY_CALENDAR';
export const FETCH_PAYMENT_HISTORY = 'FETCH_PAYMENT_HISTORY';
export const SAVE_PAYMENT_HISTORY = 'SAVE_PAYMENT_HISTORY';
export const GET_PRINTS = 'SAVE_PRINTS';

