export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const RESET_DOC_STATE = 'RESET_DOC_STATE';
export const CREATE_AS_DRAFT = 'CREATE_AS_DRAFT';
export const DELETE_DOC = 'DELETE_DOC';
export const FETCH_SIGNERS = 'FETCH_SIGNERS';
export const FETCH_DOC_SIGNERS = 'FETCH_DOC_SIGNERS';
export const SET_SIGNERS = 'SET_SIGNERS';
export const ADD_SIGNERS = 'ADD_SIGNERS';
export const SEND_DOC = 'SEND_DOC';
export const DOC_TRAIL = 'DOC_TRAIL';
export const SIGN_DOC = 'SIGN_DOC';
export const SEND_DOCUMENT = 'SEND_DOCUMENT';
export const SET_SIGNATURES = 'SET_SIGNATURES';
export const RESET_SIGNATURES = 'RESET_SIGNATURES';
export const DELETE_SIGNERS = 'DELETE_SIGNERS';
export const REQUEST_SESSION = 'REQUEST_SESSION';
export const SCHEDULE_SESSION = 'SCHEDULE_SESSION';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const CAN_UPLOAD_SEAL_AND_STAMP = 'CAN_UPLOAD_SEAL_AND_STAMP';
export const UPLOAD_CUSTOM_DOCUMENT = 'UPLOAD_CUSTOM_DOCUMENT'
