export const GET_ALL_REQUEST = 'GET_ALL_REQUEST';
export const SAVE_ALL_REQUEST = 'SAVE_ALL_REQUEST';
export const GET_REQUEST_COUNT = 'GET_REQUEST_COUNT';
export const SET_REQUEST_COUNT = 'SET_REQUEST_COUNT';
export const GET_PENDING_REQUEST = 'GET_PENDING_REQUEST';
export const GET_SCHEDULED_REQUEST = 'GET_SCHEDULED_REQUEST';
export const GET_AWAITING_PAYMENT_REQUEST = 'GET_AWAITING_PAYMENT_REQUEST';
export const GET_CANCELLED_REQUEST = 'GET_CANCELLED_REQUEST';
export const CONFIRM_REQUEST = 'CONFIRM_REQUEST';
export const GET_REQUEST_DETAILS = 'GET_REQUEST_DETAILS';
export const GET_REQUEST_DOC = 'GET_REQUEST_DOC';
export const GET_LOCKER_DETAILS = 'GET_LOCKER_DETAILS';
export const GET_LOCKER_DOC = 'GET_LOCKER_DOC';
export const CANCEL_NOTARY_REQUEST = 'CANCEL_NOTARY_REQUEST';
export const END_NOTARY_SESSION = 'END_NOTARY_SESSION';
export const COMPLETE_NOTARY_SESSION = 'COMPLETE_NOTARY_SESSION';
export const GET_SESSION_LINK = 'GET_SESSION_LINK';
export const VERIFY_LOCKER_OTP = 'VERIFY_LOCKER_OTP';
export const GET_TODAYS_REQUEST = 'GET_TODAYS_REQUEST'
