import React from 'react';
import classNames from 'classnames';
import styles from './Preloader.module.scss';

const PreLoader = ({ isVerifyEmail = false }) => (
    <div className={classNames(isVerifyEmail ? styles.grid_verify_email : styles.grid)}>
      <div className={classNames(styles.lds_ellipsis)}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
);

export default PreLoader;
